export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: "/auth/login",
    SIGNUP: "/auth/signup",
    RESET_PASSWORD: "/auth/reset-password",
    VERIFY_EMAIL: "/auth/verify-email",
  },
  USER: {
    GET_ALL: "/user",
    GET_ONE: (id) => `/user/${id}`,
    CREATE: "/user",
    UPDATE: (id) => `/user/${id}`,
    UPDATE_MEDIA_BUYERS: (id) => `/user/${id}/media-buyers`,
    GET_MEDIA_BUYERS: (id) => `/user/${id}/media-buyers`,
  },
  CAMPAIGN: {
    GET_PNLS: "/campaign/pnls",
    GET_TRENDS: "/campaign/trends",
    GET_UNIQUE_VERTICALS: "/campaign/filters/verticals",
    GET_UNIQUE_TRAFFIC_PLATFORMS: "/campaign/filters/traffic-platforms",
    GET_UNIQUE_TRAFFIC_PLATFORM_SOURCES:
      "/campaign/filters/traffic-platform-sources",
    GET_UNIQUE_AGENCIES: "/campaign/filters/agencies",
    GET_UNIQUE_MEDIA_BUYERS: "/campaign/filters/media-buyers",
    GET_METRICS: "/campaign/metrics",
    GET_HOURLY_TRENDS: "/dashboard/hourly-trends",
    GET_MEDIA_BUYERS: "/campaign/media-buyers",
    GET_VERTICALS: "/campaign/verticals",
    UPDATE_CAMPAIGN_BUDGET: "/campaign/campaign-budget",
    UPDATE_CAMPAIGN_STATUS: "/campaign/campaign-status",
    UPLOAD_CAMPAIGN_CSV: "/campaign/csv-upload",
    GET_PNL_ADS: "/campaign/pnl-ads",
    GET_PNL_ACCOUNT: "/campaign/pnl-by-account",
    GET_LANDER_CREATIVE_COMBO: "/campaign/pnl-ads-combo",
    GET_CREATIVE_PNL: "/campaign/pnl-ads-by-creative",
    UPDATE_CAMPAIGNS_MANUALLY: "/campaign/update-campaigns-manually",
    GET_CREATIVE_STATE_WISE_DATA: "/campaign/state-wise-breakdown",
    GET_CREATIVE_STATE_DATA: "/campaign/state-breakdown",
    UPDATE_CAMPAIGNS_CONFIG: "/campaign/update-campaigns-config",
    GET_CREATIVE_RANKINGS: "/campaign/creative-details",
    GET_CREATIVE_COMPARISON: "/campaign/compare-creatives",
    GET_CREATIVE_SAUCE_VIEW: "/campaign/secret-sauce",
    GET_LANDER_PNL: "/campaign/pnl-ads-by-lander",
    GET_LANDER_SAUCE_VIEW: "/campaign/lander-secret-sauce",
    GET_LANDER_SAUCE_VIEW_CHARTS: "/campaign/lander-chart",
    GET_LANDER_COMPARISON: "/campaign/compare-lander",
    GET_LANDER_DETAILS: "/campaign/lander-details",
    GET_LANDER_FC_DOMAINS: (lander) => `/lander/lander-associate/${lander}`,
    GET_LANDER_DOMAINS: "/lander/domains",
    GET_LANDER_DOMAINS_DNS: "/lander/domain-dns",
    CREATE_LANDER_DOMAINS: "/lander/domain-purchase",
    GET_CREATIVE_PNL_ASSOCIATED_ADS: "/campaign/associated-ads",
    GET_CF_CAMPAIGNS: "/campaign/cf-campaigns",
    GET_CF_LANDER: "/campaign/cf-lander",
    GET_LANDER_FLOW_CHART: "/lander/lander-flow-diagrams",
    DOMAIN_DNS_REGISTER: "/lander/domain-dns-register",

    SEARCH_LANDER_DOMAINS: "/lander/domain-check",

    GET_LANDER_FLOW_CHART_CLICK: "/lander/lander-click-metrics",
  },
  CALL_QA_AI: {
    TRANSCRIBE: "/call-qa-ai/transcribe",
    ANALYZE: "/call-qa-ai/analyze",
    MODELS: "/call-qa-ai/model-list",
    PROMPT_TEMPLATE: "/call-qa-ai/prompt-template",
    READ_RECORDS_BY_DURATION: "/call-qa-ai/read-records-by-duration",
  },
  AD_ACCOUNT: {
    ASSIGN_VERTICAL: (accountId, verticalId) =>
      `/accounts/${accountId}/assign-vertical/${verticalId}`,
    ASSIGN_MEDIA_BUYER: (accountId, mediaBuyerId) =>
      `/accounts/${accountId}/assign-mb/${mediaBuyerId}`,
    GET_AD_ACCOUNT: `/accounts`,
  },
  RINGBA: {
    GET_CALL_STATUS: "/ringba/ringba-calls-status",
    GET_TARGET_CAPS: "/ringba/target-caps",
    GET_TARGET_CAMPAIGNS: "/ringba/filter/multi-select-buyer",
  },
  QATRAINING: {
    GET_TRAINING_DATA: "/qatraining/get-training-data",
    CHECK_TRAINING_DATA_QTY: (quantity) =>
      `/qatraining/check-training-data-qty/${quantity}`,
    SAVE_TRAINING_DATA: "/qatraining/save-training-data",
    START_TRAINING: "/qatraining/start-training",
    TEST_MODEL: "/qatraining/test-model",
    DELETE_TRAINING_DATA: "/qatraining/delete-training-data",
  },
  QARINGBACALLS: {
    GET_RINGBA_CALLS_DATA: "/qaringbacalls/get-ringba-calls",
    IMPORT_FROM_RINGBA: "/qaringbacalls/import-from-ringba",
    CHECK_IMPORT_JOB: (jobId) => `/qaringbacalls/check-import-job/${jobId}`,
    DOWNLOAD_IMPORT_JOB: (jobId) => `/qaringbacalls/download-import/${jobId}`,
  },
  QAMODEL: {
    GET_MODEL_DATA: "/qamodel/get-models",
    SET_ACTIVE_MODEL: "/qamodel/set-active-model",
    CHECK_MODEL_TRAINING_STATUS: (modelName) =>
      `/qamodel/check-model-status/${modelName}`,
  },
  QAPROMPTS: {
    SAVE_PROMPT_DATA: "/qaprompts/save-prompt-data",
    GET_PROMPT_DATA: "/qaprompts/get-prompt-data",
    GET_PROMPT_DATA_BY_ID: (promptId) =>
      `/qaprompts/get-prompt-data/${promptId}`,
    UPDATE_PROMPT_DATA: "/qaprompts/update-prompt-data",
    SET_ACTIVE_PROMPT: "/qaprompts/set-active-prompt",
  },
  QADASH: {
    GET_FULL_CALL_LOGS_DATA: "/qadash/full-call-logs-data",
    GET_CARD_DATA: "/qadash/card-data",
    GET_DROPDOWN_DATA: "/qadash/dropdown-data",
    GET_BILLABLE_CHART_DATA: "/qadash/billable-chart-data",
    GET_CALL_METRICS_CHART_DATA: "/qadash/call-metrics-chart-data",
    GET_RATE_METRICS_CHART_DATA: "/qadash/rate-metrics-chart-data",
    GET_CPA_CHART_DATA: "/qadash/cpa-chart-data",
    GET_BUYER_TABLE_DATA: "/qadash/buyer-table-data",
    GET_CAMPAIGN_TABLE_DATA: "/qadash/campaign-table-data",
    GET_CREATIVE_TABLE_DATA: "/qadash/creative-table-data",
    GET_STATE_TABLE_DATA: "/qadash/state-table-data",
    GET_US_MAP_DATA: "/qadash/us-map-data",
  },
  QACALL: {
    GET_CALL_QA_CALL_DATA: (callId) => `/qacall/call-qa-call/${callId}`,
    SAVE_VOTE: "/qacall/votes",
    GET_VOTES: "/qacall/votes",
    GET_USER_VOTES: (userId) => `/qacall/votes/user/${userId}`,
    GET_VOTE_STATISTICS: "/qacall/votes/statistics",
  },
  PREFERENCES: {
    GET_PREFERENCES: "/preferences",
    UPDATE_PREFERENCES: "/preferences",
  },
  API_STATUS: {
    GET_API_STATUS_METRICS: "/api-status",
  },
  TWO_FA: {
    SETUP: "/2fa/setup",
    VERIFY: "/2fa/verify",
  },
  QACSVS: {
    GET_CSV_DATA: '/qacsvs/get-csv-data',
    GET_CSV_DATA_BY_ID: (id) => `/qacsvs/get-csv-data/${encodeURIComponent(id)}`,
    SAVE_CSV_DATA: '/qacsvs/save-csv-data',
    TEST_CSV: '/qacsvs/test-csv',
    CHECK_TEST_CSV: '/qacsvs/check-test-job',
    CANCEL_TEST_CSV: (jobId) => `/qacsvs/cancel-test-job/${jobId}`,
    CREATE_TRAINING_JOB: '/qacsvs/create-training-job',
    CHECK_TRAINING_JOB: (jobId) => `/qacsvs/check-training-job/${jobId}`,
    CANCEL_TRAINING_JOB: (jobId) => `/qacsvs/cancel-training-job/${jobId}`,
  },
};
