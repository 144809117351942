import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { publicRoutes, privateRoutes } from './routeConfig';
import NotFound from '../pages/NotFoundPage';
import Loading from '../components/common/Loader';

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>

        <Routes>
          {publicRoutes?.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PublicRoute>
                  <Component />
                </PublicRoute>
              }
            />
          ))}

          {privateRoutes?.map(({ path, component: Component,children }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute>
                  <Component />
                </PrivateRoute>
              }
            >
              {
                children &&
                children.map(({ path: childPath, component: ChildComponent }) => (
                  <Route key={childPath} path={childPath} element={<ChildComponent />} />
                ))
              }
            </Route>
          ))}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;