import baseApi from "../api.js";
import { API_ENDPOINTS } from "../endpoints.js";

export const callQaDashApi = {
  // QADASH endpoints
  getFullCallLogsData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_FULL_CALL_LOGS_DATA, { params }),
  getCardData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CARD_DATA, { params }),
  getDropdownData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_DROPDOWN_DATA, { params }),
  getBillableChartData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_BILLABLE_CHART_DATA, { params }),
  getCallMetricsChartData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CALL_METRICS_CHART_DATA, { params }),
  getRateMetricsChartData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_RATE_METRICS_CHART_DATA, { params }),
  getCpaChartData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CPA_CHART_DATA, { params }),
  getBuyerTableData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_BUYER_TABLE_DATA, { params }),
  getCampaignTableData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CAMPAIGN_TABLE_DATA, { params }),
  getCreativeTableData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CREATIVE_TABLE_DATA, { params }),
  getStateTableData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_STATE_TABLE_DATA, { params }),
  getUsMapData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_US_MAP_DATA, { params }),
  
  // QATRAINING endpoints
  getTrainingData: () =>
    baseApi.get(API_ENDPOINTS.QATRAINING.GET_TRAINING_DATA),
  checkTrainingDataQty: (quantity) =>
    baseApi.get(API_ENDPOINTS.QATRAINING.CHECK_TRAINING_DATA_QTY(quantity)),
  saveTrainingData: (data) =>
    baseApi.post(API_ENDPOINTS.QATRAINING.SAVE_TRAINING_DATA, data),
  startTraining: (data) =>
    baseApi.post(API_ENDPOINTS.QATRAINING.START_TRAINING, data),
  testModel: (data) =>
    baseApi.post(API_ENDPOINTS.QATRAINING.TEST_MODEL, data),
  deleteTrainingData: (data) =>
    baseApi.post(API_ENDPOINTS.QATRAINING.DELETE_TRAINING_DATA, { data }),

  // QARINGBACALLS endpoints
  getRingbaCallsData: (params) =>
    baseApi.get(API_ENDPOINTS.QARINGBACALLS.GET_RINGBA_CALLS_DATA, { params }),
  importFromRingba: (data) =>
    baseApi.post(API_ENDPOINTS.QARINGBACALLS.IMPORT_FROM_RINGBA, data),
  checkImportJob: (jobId) =>
    baseApi.get(API_ENDPOINTS.QARINGBACALLS.CHECK_IMPORT_JOB(jobId)),
  downloadImportJob: (jobId) =>
    baseApi.get(API_ENDPOINTS.QARINGBACALLS.DOWNLOAD_IMPORT_JOB(jobId)),

  // QAMODEL endpoints
  getModelData: (params) =>
    baseApi.get(API_ENDPOINTS.QAMODEL.GET_MODEL_DATA, { params }),
  checkModelTrainingStatus: (modelName) =>
    baseApi.get(API_ENDPOINTS.QAMODEL.CHECK_MODEL_TRAINING_STATUS(modelName)),

  // QAPROMPTS endpoints
  getPromptData: () =>
    baseApi.get(API_ENDPOINTS.QAPROMPTS.GET_PROMPT_DATA),
  getPromptDataById: (promptId) =>
    baseApi.get(API_ENDPOINTS.QAPROMPTS.GET_PROMPT_DATA_BY_ID(promptId)),
  savePromptData: (data) =>
    baseApi.post(API_ENDPOINTS.QAPROMPTS.SAVE_PROMPT_DATA, data),
  updatePromptData: (data) =>
    baseApi.put(API_ENDPOINTS.QAPROMPTS.UPDATE_PROMPT_DATA, data),
  setActivePrompt: (data) =>
    baseApi.post(API_ENDPOINTS.QAPROMPTS.SET_ACTIVE_PROMPT, data),
};
