import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../asset/image/logo.svg";
import Sun from "../../asset/image/sun.svg";
import Halfmoon from "../../asset/image/halfmoon.svg";
import DashIcon from "../../asset/image/home.svg";
import DashIconSelected from "../../asset/image/home_selected.svg";
import PNLIcon from "../../asset/image/pnl.svg";
import PNLIconSelected from "../../asset/image/pnl_selected.svg";
import TrendsIcon from "../../asset/image/trends.svg";
import TrendsIconSelected from "../../asset/image/trends_selected.svg";
import CPAIcon from "../../asset/image/cpa.svg";
import CPAIconSelected from "../../asset/image/cpa_selected.svg";
import AccountsIcon from "../../asset/image/accounts.svg";
import AccountsIconSelected from "../../asset/image/accounts_selected.svg";
import UsersIcon from "../../asset/image/users.svg";
import UsersIconSelected from "../../asset/image/users_selected.svg";
import CreativePnl from "../../asset/image/CreativePnl.svg";
import CreativePnlSelected from "../../asset/image/CreativePnlSelected.svg";
import LanderPnl from "../../asset/image/LanderPnl.svg";
import LanderPnlSelected from "../../asset/image/LanderPnlSelected.svg";

import { Typography, useTheme } from "@mui/material";
import { ColorModeContext } from "../../context/themeProvider";
import {
  useGetPreferences,
  useUpdatePreference,
} from "../../hooks/api/usePreference";

const Sidebar = () => {
  const { isDarkMode, setIsDarkMode } = useContext(ColorModeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { data } = useGetPreferences();
  const preferenceData = data?.data?.data;

  const updatePreferences = useUpdatePreference({
    onSuccess: () => {
      // Handle success if needed
    },
    onError: (error) => {
      // Handle error if needed
    },
  });

  useEffect(() => {
    if (preferenceData) {
      const mode_preference = preferenceData[0]?.mode_preference;
      setIsDarkMode(mode_preference === "dark");
    }
  }, [preferenceData, setIsDarkMode]);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
      document.body.classList.remove("light-mode");
    } else {
      document.body.classList.add("light-mode");
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  const handleClick = (event) => {
    const newMode = event.target.alt === "Moon" ? "dark" : "light";
    setIsDarkMode(newMode === "dark");
    updatePreferences.mutate({ mode_preference: newMode });
  };

  const classNameToggle = (alt) => {
    const isLightModeActive = alt === "sun" && !isDarkMode;
    const isDarkModeActive = alt === "Moon" && isDarkMode;
    return isLightModeActive || isDarkModeActive ? "active" : "";
  };

  const menuItems = [
    {
      name: "Dash",
      link: "/dashboard",
      icon: DashIcon,
      selectedIcon: DashIconSelected,
    },
    { name: "PNL", link: "/pnl", icon: PNLIcon, selectedIcon: PNLIconSelected },
    {
      name: "Creatives",
      link: "/pnl-creative",
      icon: CreativePnl,
      selectedIcon: CreativePnlSelected,
      isChild: true,
    },
    // { name: "Landers FC", link: "/lander-flow-chart", icon: LanderPnl, selectedIcon: LanderPnlSelected, isChild:true },
    {
      name: "Landers",
      link: "/pnl-landers",
      icon: LanderPnl,
      selectedIcon: LanderPnlSelected,
    },
    {
      name: "Trends",
      link: "/trends",
      icon: TrendsIcon,
      selectedIcon: TrendsIconSelected,
    },
    {
      name: "Call Qa Ai",
      link: "/qa-ai/dashboard",
      icon: CPAIcon,
      selectedIcon: CPAIconSelected,
    },
    {
      name: "Accounts",
      link: "/ad-accounts",
      icon: AccountsIcon,
      selectedIcon: AccountsIconSelected,
    },
    {
      name: "Users",
      link: "/users",
      icon: UsersIcon,
      selectedIcon: UsersIconSelected,
    },
  ];

  const handleNavigation = (link) => (event) => {
    event.preventDefault();
    const currentSearchParams = new URLSearchParams(location.search);
    navigate({
      pathname: link,
      search: currentSearchParams.toString(),
    });
  };

  const renderMenuItem = ({ name, link, icon, selectedIcon, isChild }) => {
    const isSelected = isChild
      ? location.pathname.includes(link)
      : location.pathname === link;
    return (
      <li key={name} className={isSelected ? "active" : ""}>
        <Link to={link} onClick={handleNavigation(link)}>
          <img src={isSelected ? selectedIcon : icon} alt={name} />
          <Typography
            component="span"
            sx={{ color: theme.palette.textcolors.sidebarText }}
          >
            {name}
          </Typography>
        </Link>
      </li>
    );
  };

  return (
    <>
      <div>
        <div className="header-logo">
          <img src={Logo} alt="Mission Control" />
        </div>
        <nav className="sidebarWrapper_main_nav">
          <ul>{menuItems.map(renderMenuItem)}</ul>
        </nav>
        <div className="Onoffmode">
          <ul className="onoffmode_ul">
            {[
              { src: Sun, alt: "sun" },
              { src: Halfmoon, alt: "Moon" },
            ].map(({ src, alt }) => {
              return (
                <li key={alt}>
                  <span className={classNameToggle(alt)}>
                    <img onClick={handleClick} src={src} alt={alt} />
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
