import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { callQaDashApi } from "../../api/endpoints/callQaDashApi";
import { useDateRange } from "../../context/DateRangeContext";
import { getFormattedFilters } from "../../utils/getFormattedFilters";
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const useDateRangeParams = () => {
  const { dateRange } = useDateRange();
  const [startDate, endDate] = dateRange || [];
  return {
    start_date: startDate?.format("YYYY-MM-DD"),
    end_date: endDate?.format("YYYY-MM-DD"),
  };
};

const normalizeFilters = (filters) =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    acc[key] = Array.isArray(value) ? value : [value];
    return acc;
  }, {});

export const useGetFullCallLogsData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["full-call-logs-data", queryParams, dateParams], // Include dateParams in queryKey
    queryFn: () => callQaDashApi.getFullCallLogsData(queryParams),
    enabled: !!queryParams.start_date && !!queryParams.end_date,
    ...options,
  });
};

export const useGetBuyerTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["buyer-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getBuyerTableData(queryParams),
    enabled: !!queryParams.start_date && !!queryParams.end_date,
    ...options,
  });
};

export const useGetCampaignTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["campaign-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCampaignTableData(queryParams),
    ...options,
  });
};

export const useGetCreativeTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["creative-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCreativeTableData(queryParams),
    ...options,
  });
};

export const useGetStateTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["state-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getStateTableData(queryParams),
    ...options,
  });
};

export const useGetBillableChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["billable-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getBillableChartData(queryParams),
    ...options,
  });
};


export const useGetCallMetricsChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };
  return useQuery({
    queryKey: ["call-metrics-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCallMetricsChartData(queryParams),
    ...options,
  });
};

export const useGetRateMetricsChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };
  return useQuery({
    queryKey: ["rate-metrics-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getRateMetricsChartData(queryParams),
    ...options,
  });
};

export const useGetCpaChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };
  return useQuery({
    queryKey: ["cpa-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCpaChartData(queryParams),
    ...options,
  });
};

export const useGetCardData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["card-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCardData(queryParams),
    ...options,
    keepPreviousData: true,
    staleTime: 30000,
  });
};

export const useGetDropdownData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["dropdown-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getDropdownData(queryParams),
    ...options,
  });
};

export const useGetUsMapData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["us-map-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getUsMapData(queryParams),
    ...options,
  });
};

export const useGetTrainingData = (filters = {}, options = {}) => {
  return useQuery({
    queryKey: ["training-data"],
    queryFn: () => callQaDashApi.getTrainingData(),
    ...options,
  });
};

export const useCheckTrainingDataQty = (quantity, options = {}) => {
  return useQuery({
    queryKey: ["check-training-data-qty", quantity],
    queryFn: () => callQaDashApi.checkTrainingDataQty(quantity),
    ...options,
  });
};

export const useSaveTrainingData = (options = {}) => {
  const mutation = useMutation({
    mutationFn: (data) => callQaDashApi.saveTrainingData(data),
    ...options,
  });

  return mutation;
};

export const useStartTraining = (options = {}) => {
  const mutation = useMutation({
    mutationFn: (data) => {
      console.log('Starting training with data:', data);
      return callQaDashApi.startTraining(data);
    },
    ...options,
  });

  return mutation;
};

export const useTestModel = (options = {}) => {
  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("testModel", data);
      return callQaDashApi.testModel(data);
    },
    ...options,
  });

  return mutation;
};

export const useDeleteTrainingData = () => {
  const deleteData = async (ids) => {
    await callQaDashApi.deleteTrainingData(ids);
  };
  return deleteData;
};

const ALLOWED_COLUMNS = [
  'transcription_with_time_ranges',
  'transcription',
  'timestamp_formatted',
  'time_to_connect_in_seconds',
  'time_to_call_in_seconds',
  'target_name',
  'state',
  'source',
  'recording_url',
  'publisher_name',
  'payout_amount',
  'path_name',
  'number',
  'landing_page_url',
  'job_id',
  'is_duplicate',
  'inbound_phone_number',
  'inbound_call_id',
  'id',
  'has_recording',
  'gpt_retries',
  'gpt_response',
  'gpt_processed',
  'end_call_source',
  'duration',
  'deepgram_retries',
  'deepgram_processed',
  'creative',
  'conversion_amount',
  'campaign_name',
  'campaign_id',
  'buyer',
  '_timestamp_'
];

export const useGetRingbaCallsData = () => {
  return useQuery({
    queryKey: ['ringba-calls'],
    queryFn: async () => {
      const response = await callQaDashApi.getRingbaCallsData();
      console.log('🔄 Raw API Response:', response);

      if (!response?.data) {
        throw new Error('Failed to fetch Ringba calls');
      }

      // Ensure we're returning an object with a data property
      return {
        data: Array.isArray(response.data) ? response.data : response.data.data || []
      };
    },
    keepPreviousData: true,
  });
};

export const useImportFromRingba = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post('/qaringbacalls/import-from-ringba', data);
      return response.data;
    }
  });
};

export const useCheckImportJob = () => {
  return useMutation({
    mutationFn: async ({ jobId }) => {
      const response = await axios.get(`/qaringbacalls/check-import-job/${jobId}`);
      return response.data;
    }
  });
};

export const useDownloadImportJob = () => {
  return useMutation({
    mutationFn: async ({ jobId }) => {
      const response = await axios.get(`/qaringbacalls/download-import/${jobId}`);
      return response.data;
    }
  });
};

export const useGetModelData = (params, options = {}) => {
  return useQuery({
    queryKey: ["qa-model-data", params],
    queryFn: () => callQaDashApi.getModelData(params),
    ...options,
  });
};

export const useCheckModelTrainingStatus = (modelName, options = {}) => {
  return useQuery({
    queryKey: ["check-model-training-status", modelName],
    queryFn: () => callQaDashApi.checkModelTrainingStatus(modelName),
    ...options,
  });
};

export const useSavePromptData = (options = {}) => {
  return useMutation({
    mutationFn: (data) => callQaDashApi.savePromptData(data),
    ...options,
  });
};

export const useGetPromptData = (options = {}) => {
  return useQuery({
    queryKey: ['prompt-data'],
    queryFn: async () => {
      console.log('Fetching prompt data...');
      const response = await callQaDashApi.getPromptData();
      console.log('Response from API:', response);
      const data = Array.isArray(response.data) ? response.data : [];
      console.log('Processed data:', data);
      return data;
    },
    ...options,
  });
};

export const useGetPromptDataById = (promptId, options = {}) => {
  return useQuery({
    queryKey: ['prompt-data', promptId],
    queryFn: () => callQaDashApi.getPromptDataById(promptId),
    enabled: !!promptId,
    ...options,
  });
};

export const useUpdatePromptData = (options = {}) => {
  return useMutation({
    mutationFn: (data) => callQaDashApi.updatePromptData(data),
    ...options,
  });
};

export const useSetActivePrompt = (options = {}) => {
  return useMutation({
    mutationFn: (data) => callQaDashApi.setActivePrompt(data),
    ...options,
  });
};